<template>
  <div class="pt-5">
    <common-expansion-panel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        <span class="titulo-header">Confirma tu compra</span>
      </template>
      <v-expansion-panel-content>
        <div v-if="finalData.mascota">
          <v-row class="pt5" style="background-color: rgb(242, 250, 252)">
            <v-col cols="4">
              <v-img
                :src="finalData.mascota[0].especie == 'Perro' ? images.perroImg : images.gatoImg"
                max-height="234"
                max-width="234"
                style="padding: 0px"
              ></v-img>
            </v-col>
            <v-col cols="8">
              <v-row>
                <v-col cols="12">
                  <span class="title-accord-pay">
                    {{ finalData.mascota[0].nombre }}
                  </span>
                  <div
                    style="margin-top: 20px; margin-bottom: 20px; width: 100%; height: 0.5px; background-color: rgb(10, 57, 73); opacity: 0.3;"
                  ></div>
                </v-col>
                <v-col cols="6">
                  <span class="subtitle-accord-pay">
                    Tipo de mascota
                  </span>
                </v-col>
                <v-col cols="6" style="text-align: end;">
                  <span class="title-accord-pay">
                    {{ finalData.mascota[0].especie }}
                  </span>
                </v-col>
                <v-col cols="6">
                  <span class="subtitle-accord-pay">
                    Raza
                  </span>
                </v-col>
                <v-col cols="6" style="text-align: end;">
                  <span class="title-accord-pay">
                    {{ finalData.mascota[0].raza }}
                  </span>
                </v-col>
                <v-col cols="6">
                  <span class="subtitle-accord-pay">
                    Edad
                  </span>
                </v-col>
                <v-col cols="6" style="text-align: end;">
                  <span class="title-accord-pay">
                    {{ finalData.mascota[0].anios }} Años y
                    {{ finalData.mascota[0].meses }} meses
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="pt5">
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <span class="title-accord-pay">
                    Dueño
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="title-owner">
                    Nombre:
                  </span>
                  <br />
                  <span class="text-owner">
                    {{
                      `${
                        finalData.datosContratante.nombre
                          ? finalData.datosContratante.nombre
                          : ""
                      } 
                    ${
                      finalData.datosContratante.apellidoPaterno
                        ? finalData.datosContratante.apellidoPaterno
                        : ""
                    }
                    ${
                      finalData.datosContratante.apellidoMaterno
                        ? finalData.datosContratante.apellidoMaterno
                        : ""
                    }`
                    }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="title-owner">
                    Sexo de Nacimiento:
                  </span>
                  <br />
                  <span class="text-owner">
                    {{
                      finalData.datosContratante.sexo == "hombre"
                        ? "Hombre"
                        : "Mujer"
                    }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="title-owner">
                    Edad:
                  </span>
                  <br />
                  <span class="text-owner">
                    {{
                      calculateAge(
                        finalData.datosContratante.fechaNacimiento
                      )
                    }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="title-owner">
                    Correo electrónico:
                  </span>
                  <br />
                  <span class="text-owner">
                    {{ finalData.datosContratante.correo }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="title-owner">
                    Teléfono:
                  </span>
                  <br />
                  <span class="text-owner">
                    {{ finalData.datosContratante.telefono }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn
                block
                rounded
                dark
                outlined
                color="#00a7e4"
                @click="$emit('goBack',2)"
              >
                <span style="padding-right: 11%;">
                  Regresar
                </span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                block
                rounded
                dark
                class="common-botton"
                @click="toEmisionModal=true"
              >
                <span style="padding-right: 11%;">
                  Ir al pago !
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-expansion-panel-content>
    </common-expansion-panel>
    <CommonActionModal 
      :dialog="toEmisionModal"
      :title="titleModal"
      :subtitle="subtitleModal"
      :bottonText="bottonText"
      @userChoice="choiseEleccion"
      @closeModal="toEmisionModal = false"
    >
    </CommonActionModal>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import dataLoading from "@/helpers/data/data-loading-gif.json";
import moment from "moment";
import CommonActionModal from "@/components/commonComponents/CommonActionModal.vue";

export default {
  components: {
    CommonExpansionPanel,
    CommonActionModal
  },
  props: {
    currentStep: Number,
    finalData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      currentPanel: 0,
      isFormValid: false,
      readonly: false,
      images: dataLoading,
      toEmisionModal:false,
      titleModal:'Vamos al pago',
      subtitleModal:'¿Los datos son correctos?',
      bottonText:'Si, vamos al pago.'
    };
  },

  computed: {},

  watch: {
    currentStep(val) {
      if (val == 3) {
        this.currentPanel = 0;
        this.readonly = false;
      } else {
        this.currentPanel = 1;
        this.readonly = true;
      }
    },
  },

  methods: {
    calculateAge(birthdate) {
      const birthDate = moment(birthdate, "DD/MM/YYYY");
      const today = moment();
      const age = today.diff(birthDate, "years");
      return age;
    },
    choiseEleccion(val) {
      if(val){
        this.toEmisionModal = false
        this.$emit('goToPay')
      }
      else this.toEmisionModal = false
    },
  },

  created() {},
};
</script>
