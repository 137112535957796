<template>
  <div class="pt-5">
    <common-expansion-panel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        <span class="titulo-header">Ahora queremos conocerte a ti</span>
      </template>
      <v-expansion-panel-content>
        <span class="title-contratante"
          >¿Nos ayudas con esta información?
        </span>
        <v-form ref="form" v-model="isFormValid" lazy-validation class="mt-5">
          <v-row>
            <v-col class="col-12" md="12">
              <span class="gray mb-32">
                ¿El dueño de es el contratante?
                <span class="red--text">*</span>
              </span>
              <div class="mt-5">
                <v-btn-toggle
                  v-model="form.datosContratante.pagador"
                  rounded
                  :rules="requiredRule"
                  color="#00a7e4"
                  style="width: 100%;"
                >
                  <v-btn :value="true" style="width: 50%;">
                    <span class="hidden-sm-and-down">Si</span>
                  </v-btn>
                  <v-btn :value="false" style="width: 50%;">
                    <span class="hidden-sm-and-down">No, es alguien más</span>
                  </v-btn>
                </v-btn-toggle>
              </div>
            </v-col>
            <v-col class="col-12" md="12" v-if="!form.datosContratante.pagador">
              <span class="title-contratante">Datos del dueño</span>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.datosPagador.nombre"
                    :rules="requiredRule"
                    outlined
                  >
                    <template #label>
                      <span>Nombre(s)<span class="red--text">*</span></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.datosPagador.apellidoPaterno"
                    outlined
                    :rules="requiredRule"
                  >
                    <template #label>
                      <span
                        >Apellido Paterno <span class="red--text">*</span></span
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.datosPagador.apellidoMaterno"
                    :rules="requiredRule"
                    outlined
                  >
                    <template #label>
                      <span
                        >Apellido Materno <span class="red--text">*</span></span
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.datosPagador.fechaNacimiento"
                    outlined
                    :rules="requiredRule"
                    type="date"
                  >
                    <template #label>
                      <span
                        >¿Cuándo nació?<span class="red--text">*</span></span
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col class="col-12" md="12">
                  <span class="gray mb-32">
                    ¿Cuál es su sexo de nacimiento?
                    <span class="red--text">*</span>
                  </span>
                  <div class="mt-5">
                    <v-btn-toggle
                      v-model="form.datosContratante.sexo"
                      rounded
                      color="#00a7e4"
                      :rules="requiredRule"
                      style="width: 100%;"
                    >
                      <v-btn value="hombre" style="width: 50%;">
                        <span class="hidden-sm-and-down">Hombre</span>
                      </v-btn>
                      <v-btn value="mujer" style="width: 50%;">
                        <span class="hidden-sm-and-down">Mujer</span>
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <div class="d-flex align-center">
                <span class="text-body-1 mr-3"
                  >¿Requiere factura para esta compra?</span
                >
                <v-switch
                  v-model="form.datosPagador.requiereFactura"
                  inset
                  color="#00a7e4"
                ></v-switch>
              </div>
            </v-col>
            <v-col class="col-12" md="12">
              <span class="title-contratante">Datos del contratante </span>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field v-model="form.datosContratante.nombre" outlined>
                    <template #label>
                      <span>Nombre(s)<span class="red--text">*</span></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.datosContratante.apellidoPaterno"
                    outlined
                    :rules="requiredRule"
                  >
                    <template #label>
                      <span
                        >Apellido Paterno <span class="red--text">*</span></span
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.datosContratante.apellidoMaterno"
                    outlined
                    :rules="requiredRule"
                  >
                    <template #label>
                      <span
                        >Apellido Materno <span class="red--text">*</span></span
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.datosContratante.fechaNacimiento"
                    outlined
                    type="date"
                    :rules="requiredRule"
                  >
                    <template #label>
                      <span
                        >¿Cuándo nació?<span class="red--text">*</span></span
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col class="col-12" md="12">
                  <span class="gray mb-32">
                    ¿Cuál es su sexo de nacimiento?
                    <span class="red--text">*</span>
                  </span>
                  <div class="mt-5">
                    <v-btn-toggle
                      v-model="form.datosContratante.sexo"
                      rounded
                      :rules="requiredRule"
                      color="#00a7e4"
                      style="width: 100%;"
                    >
                      <v-btn value="hombre" style="width: 50%;">
                        <span class="hidden-sm-and-down">Hombre</span>
                      </v-btn>
                      <v-btn value="mujer" style="width: 50%;">
                        <span class="hidden-sm-and-down">Mujer</span>
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.datosContratante.rfc"
                    outlined
                    id="rfc"
                    :rules="rfcRules"
                  >
                    <template #label>
                      <span
                        >¿Cuál es tu RFC?<span class="red--text">*</span></span
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  v-if="form.datosPagador.requiereFactura"
                >
                  <v-autocomplete
                    label="¿Con qué régimen fiscal quieres que hagamos tu factura?"
                    color="#00a7e4"
                    outlined
                    clearable
                    :items="taxTypes"
                    item-text="value"
                    item-value="value"
                    hide-details
                    v-model="form.datosPagador.regimenFiscal"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <br />
          <span class="title-contratante">¿Cuál es tu domicilio?</span>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.direccion.codigoPostal"
                outlined
                id="cp"
                :rules="requiredRule"
                maxlength="5"
                counter
              >
                <template #label>
                  <span>Código Postal<span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                label="Colonia"
                color="#00a7e4"
                outlined
                clearable
                :items="colonias"
                item-text="COLONIA"
                item-value="cod_colonia"
                hide-details
                v-model="form.direccion.cod_colonia"
                :rules="requiredRule"
                :loading="loadingCP"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                label="Estado"
                color="#00a7e4"
                outlined
                readonly
                :items="colonias"
                item-text="ESTADO"
                item-value="cod_ciudad"
                hide-details
                :rules="requiredRule"
                v-model="form.direccion.cod_ciudad"
                :loading="loadingCP"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                label="Municipio"
                color="#00a7e4"
                outlined
                readonly
                :items="colonias"
                item-text="MUNICIPIO"
                item-value="cod_municipio"
                hide-details
                :rules="requiredRule"
                v-model="form.direccion.cod_municipio"
                :loading="loadingCP"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.direccion.calle"
                outlined
                id="calle"
                :rules="requiredRule"
              >
                <template #label>
                  <span>Calle<span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.direccion.numeroExterior"
                outlined
                id="numero_exterior"
                :rules="requiredRule"
              >
                <template #label>
                  <span>No. Exterior<span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.direccion.numeroInterior"
                outlined
                id="numero_interior"
                :rules="requiredRule"
              >
                <template #label>
                  <span>No. Interior<span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <span class="title-contratante">¿Dónde te podemos contactar?</span>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.datosContratante.correo"
                outlined
                id="correo"
                :rules="requiredRule"
              >
                <template #label>
                  <span>Correo<span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.datosContratante.telefono"
                outlined
                id="telefono"
                maxlength="10"
                counter
                :rules="requiredRule"
              >
                <template #label>
                  <span>Telefono<span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-container fluid>
                <v-checkbox v-model="checkbox" :rules="requiredRule">
                  <template v-slot:label>
                    <div>
                      Acepto
                      <a
                        target="_blank"
                        href="https://inter.mx/aviso-de-privacidad"
                        @click.stop
                      >
                        Aviso de privacidad
                      </a>
                      y
                      <a
                        target="_blank"
                        href="https://storage.googleapis.com/inter-public-cloudstorage-prod/gmm/terminoscondiciones/TYC%20APP%20Intermx.pdf"
                        @click.stop
                      >
                        Términos y Condiciones
                      </a>
                    </div>
                  </template>
                </v-checkbox>
              </v-container>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn
                block
                rounded
                dark
                outlined
                color="#00a7e4"
                @click="$emit('goBack',1)"
              >
                <span style="padding-right: 11%;">
                  Regresar
                </span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                block
                rounded
                dark
                class="common-botton"
                :disabled="!isFormValid"
                @click="nextStep()"
              >
                <span style="padding-right: 11%;">
                  Siguiente
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </common-expansion-panel>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import {
  getRazas,
  getTaxType,
  getCodePostInter,
} from "@/services/mascota/mascota.service";
import { getCotizacionesByFiltros } from "@/services/cotizaciones/cotizaciones.service";
import moment from "moment";
import RfcFacil from "rfc-facil";

export default {
  components: {
    CommonExpansionPanel,
  },

  props: {
    currentStep: Number,
  },

  data() {
    return {
      currentPanel: 1,
      isFormValid: false,
      loading: false,
      requiredChecks: {
        1: false,
        2: false,
        3: false,
        4: false,
      },
      form: {
        datosContratante: {
          nombre: "",
          apellidoPaterno: "",
          apellidoMaterno: "",
          sexo: "hombre",
          fechaNacimiento: "",
          pagador: true,
          correo: "",
          telefono: "",
          rfc: "",
          requiereFactura: false,
          regimenFiscal: "",
        },
        datosPagador: {
          nombre: "",
          apellidoPaterno: "",
          apellidoMaterno: "",
          sexo: "",
          fechaNacimiento: "",
          correo: "",
          telefono: "",
          rfc: "",
          requiereFactura: false,
          regimenFiscal: "",
        },
        direccion: {
          codigoPostal: "",
          estado: "",
          municipio: "",
          colonia: "",
          calle: "",
          numeroExterior: "",
          numeroInterior: "",
          cod_colonia: "",
          cod_ciudad: "",
          cod_municipio: "",
          cod_dpto: "",
        },
      },
      taxTypes: [],
      generos: ["Macho", "Hembra"],
      localRules: {
        alphanumeric: (v) =>
          !v ||
          !!v.match(/^[0-9a-zA-Z]+$/) ||
          "Este campo solo acepta letras y números",
      },
      species: [],
      razas: [],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      responseAseguradora: {},
      loadingRazas: false,
      
      rfcRules: [
        (v) =>
          v.length === 10 ||
          v.length === 12 ||
          v.length === 13 ||
          "RFC debe tener 10, 12 o 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^[A-Za-z\d]{10,13}$/.test(v) ||
          "RFC no es válido, asegúrate de tener la longitud correcta y utiliza caracteres alfanuméricos",
      ],
      colonias: [],
      loadingCP: false,
      readonly: false,
      checkbox: false,
    };
  },

  computed: {},

  watch: {
    async "form.especie"(val) {
      this.loadingRazas = true;
      let cod_especie = val == "Perro" ? 1 : 2;
      this.getRazas(cod_especie);
    },
    currentStep(val) {
      if (val == 2) {
        this.currentPanel = 0;
        this.readonly = false;
      } else {
        this.currentPanel = 1;
        this.readonly = true;
      }
    },
    "form.direccion.codigoPostal"(val) {
      if (val.length == 5) {
        this.form.direccion.estado = "";
        this.form.direccion.municipio = "";
        this.form.direccion.colonia = "";
        this.getCodePostInter(val);
      }
    },
    "form.direccion.cod_colonia"(val) {
      if (val) {
        this.form.direccion.estado = this.colonias.find(
          (e) => e.cod_colonia == val
        ).ESTADO;
        this.form.direccion.municipio = this.colonias.find(
          (e) => e.cod_colonia == val
        ).MUNICIPIO;
        this.form.direccion.colonia = this.colonias.find(
          (e) => e.cod_colonia == val
        ).COLONIA;
        this.form.direccion.cod_ciudad = this.colonias.find(
          (e) => e.cod_colonia == val
        ).cod_ciudad;
        this.form.direccion.cod_municipio = this.colonias.find(
          (e) => e.cod_colonia == val
        ).cod_municipio;
        this.form.direccion.cod_dpto = this.colonias.find(
          (e) => e.cod_colonia == val
        ).cod_dpto;
      }
    },
    "form.datosContratante.nombre"(){
      this.actualizarRfc()
    },
    "form.datosContratante.apellidoMaterno"(){
      this.actualizarRfc()
    },
    "form.datosContratante.apellidoPaterno"(){
      this.actualizarRfc()
    },
    "form.datosContratante.fechaNacimiento"(){
      this.actualizarRfc()
    }
  },

  methods: {
    async getRazas(cod_especie) {
      this.razas = [];
      const resp = await getRazas(cod_especie);
      this.razas = resp.data.races;
      this.loadingRazas = false;
    },
    async getCotizacion() {
      this.responseAseguradora = await getCotizacionesByFiltros({
        grouped: true,
        cotizacion_general: this.$route.params.cotizacionGeneral,
      });
      this.planes = JSON.parse(this.responseAseguradora[0].response);
      this.getContratanteInfo();
    },
    getContratanteInfo() {
      let request = JSON.parse(this.responseAseguradora[0].request);
      this.form.datosContratante.nombre = request.nombreDuexo;
      this.form.datosContratante.apellidoPaterno = request.apellidoPaterno;
      this.form.datosContratante.apellidoMaterno = request.apellidoMaterno;
      this.form.datosContratante.telefono = request.telefono;
      this.form.datosContratante.correo = request.correo;
    },
    async getSpecies() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": "" + sessionStorage.interToken,
        },
      };
      const resp = await mainAxios.get("/v1/mascota/species", config);
      this.species = resp.data.data.species;
    },
    nextStep() {
      if (!this.$refs.form.validate()) {
        return;
      } else {
        if (this.form.datosContratante.pagador) {
          this.form.datosPagador.nombre = this.form.datosContratante.nombre;
          this.form.datosPagador.apellidoMaterno = this.form.datosContratante.apellidoMaterno;
          this.form.datosPagador.apellidoPaterno = this.form.datosContratante.apellidoPaterno;
          this.form.datosPagador.correo = this.form.datosContratante.correo;
          this.form.datosPagador.fechaNacimiento = this.form.datosContratante.fechaNacimiento;
          this.form.datosPagador.pagador = this.form.datosContratante.pagador;
          this.form.datosPagador.regimenFiscal = this.form.datosContratante.regimenFiscal;
          this.form.datosPagador.requiereFactura = this.form.datosContratante.requiereFactura;
          this.form.datosPagador.rfc = this.form.datosContratante.rfc;
          this.form.datosPagador.sexo = this.form.datosContratante.sexo;
          this.form.datosPagador.telefono = this.form.datosContratante.telefono;
        }
        this.$emit("ChangeOnlyStep", 3, Object.assign({},this.form));
      }
    },
    async getTaxtype() {
      this.taxTypes = await getTaxType();
    },
    async getCodePostInter(cp) {
      this.loadingCP = true;
      this.colonias = await getCodePostInter(cp);
      this.loadingCP = false;
    },
    actualizarRfc() {
      if (
        !this.form.datosContratante.nombre ||
        !this.form.datosContratante.apellidoPaterno ||
        !this.form.datosContratante.apellidoMaterno ||
        !this.form.datosContratante.fechaNacimiento
      )
        return;
      const arregloNacimiento = moment(this.form.datosContratante.fechaNacimiento)
        .format("MM-DD-YYYY")
        .split("-")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.form.datosContratante.nombre,
        firstLastName: this.form.datosContratante.apellidoPaterno,
        secondLastName: this.form.datosContratante.apellidoMaterno,
        day: arregloNacimiento[1],
        month: arregloNacimiento[0],
        year:arregloNacimiento[2] ,
      });
      this.form.datosContratante.rfc = rfc
    },
  },

  async created() {
    this.getCotizacion();
    this.getSpecies();
    this.getTaxtype();
  },
};
</script>
