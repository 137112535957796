<template>
  <div class="pt-5">
    <common-expansion-panel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        <span class="titulo-header"
          >Queremos saber más de {{ form.nombre }}</span
        >
      </template>
      <v-expansion-panel-content>
        <span class="text-body-1 px-1">Datos de tu mascota</span>
        <v-form ref="form" v-model="isFormValid" lazy-validation class="mt-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field v-model="form.nombre" outlined>
                <template #label>
                  <span>¿Cómo se llama? <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="col-12" md="6">
              <span class="gray mb-32"
                >¿Perro o gato? <span class="red--text">*</span></span
              >
              <v-radio-group v-model="form.especie" row :rules="requiredRule">
                <v-radio
                  label="Perro"
                  :value="'Perro'"
                  color="#00a7e4"
                ></v-radio>
                <v-radio label="Gato" :value="'Gato'" color="#00a7e4"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="col-12" md="6">
              <span class="gray mb-32"
                >¿Es Macho o Hembra? <span class="red--text">*</span></span
              >
              <v-radio-group v-model="form.sexo" row :rules="requiredRule">
                <v-radio
                  label="Macho"
                  :value="'Macho'"
                  color="#00a7e4"
                ></v-radio>
                <v-radio
                  label="Hembra"
                  :value="'Hembra'"
                  color="#00a7e4"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="form.cod_raza"
                outlined
                label="¿Qué raza es?*"
                :items="razas"
                :rules="requiredRule"
                :loading="loadingRazas"
                item-text="txt_desc_raza"
                item-value="cod_raza"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="form.anios"
                outlined
                :rules="numericRule"
                label="Edad"
                :items="[0, 1, 2, 3, 4, 5, 6, 7, 8]"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="form.meses"
                outlined
                :rules="numericRule"
                label="Meses"
                :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
              >
              </v-autocomplete>
            </v-col>
            <v-col class="col-12" md="6">
              <span class="gray mb-32"
                >¿Tiene o ha tenido alguna enfermedad?
                <span class="red--text">*</span></span
              >
              <v-radio-group
                v-model="form.enfermedades"
                row
                :rules="requiredRule"
              >
                <v-radio label="Si" :value="'Si'" color="#00a7e4"></v-radio>
                <v-radio label="No" :value="'No'" color="#00a7e4"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.enfermedad"
                outlined
                :rules="minimumLengthRule"
                v-if="form.enfermedades == 'Si'"
              >
                <template #label>
                  <span>¿Qué enfermedad?<span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.descripcionMascota"
                outlined
                :rules="minimumLengthRule"
              >
                <template #label>
                  <span
                    >Descríbenos, ¿cómo es físicamente tu cuatropatitas?
                    <span class="red--text">*</span></span
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn
                block
                rounded
                dark
                outlined
                color="#00a7e4"
                @click="$router.go(-1)"
              >
                <span style="padding-right: 11%;">
                  Regresar
                </span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                block
                rounded
                dark
                class="common-botton"
                :disabled="!isFormValid"
                @click="nextStep()"
              >
                <span style="padding-right: 11%;">
                  Siguiente
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </common-expansion-panel>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import { getRazas } from "@/services/mascota/mascota.service";
import { getCotizacionesByFiltros } from "@/services/cotizaciones/cotizaciones.service";

export default {
  components: {
    CommonExpansionPanel,
  },

  props: {
    currentStep: Number,
  },

  data() {
    return {
      currentPanel: 0,
      isFormValid: false,
      loading: false,
      requiredChecks: {
        1: false,
        2: false,
        3: false,
        4: false,
      },
      form: {
        id: "",
        nombre: "",
        especie: "",
        cod_raza: "",
        raza: "",
        meses: "",
        anios: "",
        descripcionMascota: "",
        sexo: "",
        enfermedades: "",
        comidaFavorita: "",
        foto: "",
        chip: false,
        referencia: "",
        pedigree: false,
        addPhoto: false,
      },
      generos: ["Macho", "Hembra"],
      localRules: {
        alphanumeric: (v) =>
          !v ||
          !!v.match(/^[0-9a-zA-Z]+$/) ||
          "Este campo solo acepta letras y números",
      },
      species: [],
      razas: [],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      numericRule: [
        (v) =>
          (v !== null && v !== undefined && v !== "") ||
          "El campo es requerido",
      ],

      minimumLengthRule: [
        (v) =>
          (v && v.length >= 11) || "El texto debe tener al menos 11 caracteres",
      ],
      responseAseguradora: {},
      loadingRazas: false,
      readonly: false,
    };
  },

  computed: {},

  watch: {
    async "form.especie"(val) {
      this.loadingRazas = true;
      let cod_especie = val == "Perro" ? 1 : 2;
      this.getRazas(cod_especie);
    },
    currentStep(val) {
      if (val == 1) {
        this.currentPanel = 0;
        this.readonly = false;
      } else {
        this.currentPanel = 1;
        this.readonly = true;
      }
    },
    "form.cod_raza"(val) {
      this.form.raza = this.razas.find((e) => e.cod_raza == val).txt_desc_raza;
    },
  },

  methods: {
    async getRazas(cod_especie) {
      this.razas = [];
      const resp = await getRazas(cod_especie);
      this.razas = resp.data.races;
      this.loadingRazas = false;
    },
    async getCotizacion() {
      this.responseAseguradora = await getCotizacionesByFiltros({
        grouped: true,
        cotizacion_general: this.$route.params.cotizacionGeneral,
      });
      this.planes = JSON.parse(this.responseAseguradora[0].response);
      this.getMascotaCotizacionInfo();
    },
    getMascotaCotizacionInfo() {
      let request = JSON.parse(this.responseAseguradora[0].request);
      this.form.nombre = request.nombreMascota;
      this.form.especie = request.tipoMascotaId == 1 ? "Perro" : "Gato";
      console.log({ request });
    },
    async getSpecies() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": "" + sessionStorage.interToken,
        },
      };
      const resp = await mainAxios.get("/v1/mascota/species", config);
      this.species = resp.data.data.species;
    },
    nextStep() {
      if (!this.$refs.form.validate()) {
        return;
      } else this.$emit("ChangeOnlyStep", 2, this.form);
    },
  },

  async created() {
    this.getCotizacion();
    this.getSpecies();
  },
};
</script>
