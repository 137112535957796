<template>
  <div>
    <v-row>
      <v-col cols="12">
        <common-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <span class="subtitulo-header-2 font-montserrat"
                  >Cotización</span
                >
              </v-col>
              <v-col cols="6">
                <span class="subtitulo-header-2 font-montserrat"
                  >#{{ cotizacionGeneral }}</span
                >
              </v-col>
            </v-row>
          </v-card-text>
        </common-card>
      </v-col>
      <v-col cols="12">
        <common-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <span class="font-montserrat">
                  Plan
                </span>
              </v-col>
              <v-col cols="6">
                <span class="subtitulo-header-2 font-montserrat">
                  {{ currentCotizacion.PlanNombreInter }}
                </span>
              </v-col>
              <v-col cols="6">
                <span class="font-montserrat">
                  Total
                </span>
              </v-col>
              <v-col cols="6">
                <span class="subtitulo-header-2 font-montserrat">
                  {{ currencyFormatt(currentCotizacion.PlanPrimaTotal) }}
                </span>
              </v-col>
              <v-col cols="6">
                <span class="font-montserrat">
                  Modalidad de pago
                </span>
              </v-col>
              <v-col cols="6">
                <span class="subtitulo-header-2 font-montserrat">
                  De contado
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </common-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getCotizacionesByFiltros } from "@/services/cotizaciones/cotizaciones.service";
import CommonCard from "@/components/commonComponents/CommonCard.vue";

export default {
  components: {
    CommonCard
  },

  data() {
    return {
      logo:
        "https://todoseguros.com.mx/wp-content/uploads/2021/06/Seguros-GMX.jpg",
      cotizacionMascotaDetail: {},
      selectedCotizacion: "",
      responseAseguradora: [],
      planes: [],
    };
  },

  methods: {
    getPlanSumaAsegurada() {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(this.currentCotizacion.PlanSumaAsegurada);
    },
    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });
      return formatoMonedaMXN.format(val);
    },
    async getCotizacion() {
      this.responseAseguradora = await getCotizacionesByFiltros({
        grouped: true,
        cotizacion_general: this.$route.params.cotizacionGeneral,
      });
      this.planes = JSON.parse(this.responseAseguradora[0].response);
    },
  },
  computed: {
    currentCotizacion() {
      var coberturas = [];
      if (this.planes.length > 0 && this.selectedCotizacion) {
        coberturas = this.planes.find(
          (e) => e.PlanCodigo == this.selectedCotizacion
        );
      }
      return coberturas;
    },
  },
  mounted() {
    this.getCotizacion();
    this.cotizacionMascotaDetail = {};
    this.selectedCotizacion = this.$route.params.planID;
    this.cotizacionGeneral = this.$route.params.cotizacionGeneral;
  },
};
</script>
